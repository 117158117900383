.sections-open {
  min-height: 100vh;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  @media (min-width: 768px) {
    padding: 2rem;
  }

  &__container {
    width: 100vw;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 24px;
    background-color: #fff;
  }

  h1 {
    text-align: center;
  }

  &__item {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-height: 200px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    &--left {
      margin-right: auto;
    }

    &--right {
      margin-left: auto;
    }

    &--expanded {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__number {
    font-size: 0.875rem;
    opacity: 0.6;
  }

  &__title {
    font-size: 0.8rem;
    font-weight: bold;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    opacity: 0.6;
  }

  &__expanded {
    flex: 1;
    margin-left: 1.5rem;
  }

  &__subtitle {
    font-size: 0.875rem;
    opacity: 0.8;
  }

  &__description {
    margin-top: 1rem;
    opacity: 0.6;
  }
}
