.animated-heading {
  font-size: 2.25rem; // text-4xl
  font-weight: 700;
  letter-spacing: -0.025em;
  line-height: 1.2;
  text-align: center;
  color: #ae1100;

  @media (min-width: 768px) {
    font-size: 3.75rem; // text-6xl
  }

  &__word {
    display: inline-block;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  &__char {
    display: inline-block;
    white-space: pre;
    will-change: transform, opacity;

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  // Optional: Add different themes/variations
  &--light {
    color: #ffffff;
  }

  &--dark {
    color: #000000;
  }

  // Optional: Add hover effects
  &:hover {
    .animated-heading__char {
      display: inline-block;
      transition: transform 0.2s ease;

      &:hover {
        transform: translateY(-2px);
      }
    }
  }
}
