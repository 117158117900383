.main-container {
  display: block;

  .main-cta-style {
    position: fixed;
    right: 0;
    top: 50%;
    height: max-content;
    width: max-content;
    background-color: rgb(231, 60, 55);
    z-index: 10;
    color: #fff;
    font-weight: 800;
    pointer-events: all;
  }

  .custom-shape-divider-top-1729744919 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 3;
  }

  .custom-shape-divider-top-1729744919 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
  }

  .custom-shape-divider-top-1729744919 .shape-fill {
    fill: #d4d4d4;
  }
}

.nav-menu-dropdown {
  width: 100vw;
  transition: 0.5s height ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  box-shadow: 0px 0px 25px #6e6e6e;
  z-index: 20;
  background-color: black;
}

.nav-section-1,
.nav-section-2 {
  font-size: 22px;
  padding: 24px 24px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  gap: 12px;
}

.nav-section-1 {
  justify-content: center;
}

.nav-menu-dropdown-open {
  height: 300px;
}

.nav-menu-dropdown-hidden {
  height: 0;
}

.main-content-wrapper {
  transition: 0.5s all ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-content-transform {
  transform: translateY(100px);
}

.text-animation,
.text-animation-2 {
  position: relative;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-size: clamp(40px, 14vw, 80px);
  font-weight: 800;
  text-transform: uppercase;
  margin-top: auto;
  margin-left: 24px;
  text-align: start;
  margin-bottom: 16px;
  color: #ffffff;
  mix-blend-mode: difference;

  cursor: default;
  .inline-block {
    display: inline-block;
  }

  .absolute {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.glitchWrapper {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.text-animation-2 {
  margin-top: 0;
}

.nav-bar {
  position: fixed; // Changed from absolute to fixed for sticky behavior
  top: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  color: white;
  z-index: 20;
  padding: 12px 24px;
  transition: transform 300ms ease-in-out;
  background-color: transparent;
  backdrop-filter: blur(20px);

  &.hidden {
    transform: translateY(-100%);
  }

  &.scrolled {
    background-color: rgba(0, 0, 0, 0.418);
    backdrop-filter: blur(10px);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 12px 24px;
  }

  .head {
    font-size: 18px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 30px;
      width: 30px;
    }
  }

  .menu {
    font-size: 14px;
    padding: 4px;
    display: flex;
    gap: 16px;
  }

  .menu-btn {
    cursor: pointer;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    .hamburger-menu {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 30px; /* Adjust width */
      height: 20px; /* Adjust height */
    }

    .line-menu {
      height: 2px; /* Line thickness */
      background-color: rgb(0, 0, 0); /* Line color */
      border-radius: 2px; /* Optional: for rounded corners */
    }
  }
}

.buffer {
  height: 100svh;
}

.homeBgStyle {
  height: 100svh;
  width: 100vw;
  background-size: cover;
  background-color: #ffffff;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  clip-path: inset(0 0 0 0);
  overflow: hidden;
  position: relative;

  .homeSkylineBg {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 0;
    opacity: 0.2;
    &::after {
      content: " ";
      position: absolute;
      inset: 0;
      background-image: url("../assets/skyline.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: top;
      height: 100%;
    }
    &::before {
      content: " ";
      position: absolute;
      inset: 0;
      background-color: #fff;
      z-index: 2;
      animation: scaleYAnimation 1s ease-in-out forwards;
    }
  }
  @keyframes scaleYAnimation {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }

  & * {
    z-index: 3;
  }

  .header-wrapper {
    margin-top: auto;
    font-size: 1.2em;
    padding-left: 24px;
    letter-spacing: -1px;
    font-weight: 500;
    line-height: 28px;
    mix-blend-mode: difference;
    p {
      margin: 0;
    }
  }

  .description {
    margin-top: auto;
    padding: 0 20px 0 20px;
    font-size: small;
    display: grid;
    grid-template-columns: 4fr 3fr;
    justify-content: space-between;
    text-align: justify;
    text-transform: capitalize;
    mix-blend-mode: difference;
  }

  .typewriter {
    width: max-content;
  }
  .typewriter p {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.1em solid rgb(255, 255, 255); /* The typwriter cursor */
    padding-right: 4px;
    white-space: nowrap; /* Keeps the content on a single line */
    animation: typing 1.5s steps(40, end), blink-caret 0.75s step-end infinite;
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  /* The typewriter cursor effect */
  @keyframes blink-caret {
    from,
    to {
      border-color: transparent;
    }
    50% {
      border-color: rgb(255, 255, 255);
    }
  }
}

.image-section {
  height: 300svh;
  width: 100vw;
  color: #000000;
  position: relative;
  background-color: #141414;
  text-align: center;

  .header-wrapper {
    position: absolute;
    top: 5svh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .heading {
    font-size: clamp(24px, 18vw, 96px);
    font-weight: 600;
    text-align: center;
  }

  .sub-heading {
    max-width: 50%;
    text-align: center;
  }

  .wrapper {
    background-color: #ffffff;
    height: 100svh;
    width: 100vw;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-bottom: 10svh;
    text-align: center;
    flex-direction: column;
    position: sticky;
    top: 0px;
    overflow: hidden;

    .edges {
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 20px;
      --b: 3px;
      --c: rgb(59, 59, 59);
      --w: 20px;

      border: var(--b) solid #0000;
      --_g: #0000 90deg, var(--c) 0;
      --_p: var(--w) var(--w) border-box no-repeat;
      background: conic-gradient(
            from 90deg at top var(--b) left var(--b),
            var(--_g)
          )
          0 0 / var(--_p),
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g))
          100% 0 / var(--_p),
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0
          100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
          100% 100% / var(--_p);
    }

    .image-array {
      display: flex;
      gap: 20px;
      padding: 100px 0 0 50px;
      transition: all 0.1s ease-out;
    }

    .image {
      height: 60svh;
      width: 300px;
      display: flex;
      gap: 2px;
      flex-direction: column;
      overflow: hidden;

      .card-image-wrapper {
        height: 50%;
        overflow: hidden;

        .card-style {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text-wrapper {
        background-color: #ffffff;
        height: 50%;
        padding: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        border: 1px dashed #5c5c5c5e;

        .title {
          font-size: 1.3rem;
          margin-bottom: 10px;
          text-transform: uppercase;
          text-align: start;
        }

        .sub-title {
          font-size: 1rem;
          color: #2b2b2b;
          text-align: start;
        }
      }
    }
  }
}

.description-section {
  background-color: #ffffff;
  width: clamp(200px, 100%, 500px);
  aspect-ratio: 1/1;
  padding: 64px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  position: relative;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;

  .text-wrapper-content-section {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    gap: 24px;
    padding: 0 24px;
  }

  .why-us-img {
    width: 80%;
    aspect-ratio: 1/1;
  }

  .edges {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    opacity: 0.2;
    --b: 3px;
    --c: rgb(59, 59, 59);
    --w: 20px;

    border: var(--b) solid #0000;
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background: conic-gradient(
          from 90deg at top var(--b) left var(--b),
          var(--_g)
        )
        0 0 / var(--_p),
      conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100%
        0 / var(--_p),
      conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0
        100% / var(--_p),
      conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g))
        100% 100% / var(--_p);
  }

  .heading {
    margin-right: auto;
    font-weight: 500;
    z-index: 2;
    color: #000000;
    span {
      animation: blink 1s infinite;
    }
    @keyframes blink {
      0% {
        opacity: 0.2;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.2;
      }
    }
  }

  .description {
    z-index: 2;
    margin-top: 24px;
  }

  .description * {
    font-size: 1.5em;
    color: hsla(0, 0%, 9%, 0.2);
    font-weight: 500;
    background-clip: text;
    display: inline;
    background-image: linear-gradient(90deg, rgb(0, 0, 0), rgb(0, 0, 0));
    background-repeat: no-repeat;
    z-index: 2;
  }
}

.dark-section-style {
  background-color: #141414;
  color: #d6d6d6;

  .description * {
    font-size: 1.4em;
    color: hsla(0, 0%, 84%, 0.2);
    font-weight: 500;
    background-clip: text;
    display: inline;
    background-image: linear-gradient(
      90deg,
      rgb(236, 236, 236),
      rgb(236, 236, 236)
    );
    background-repeat: no-repeat;
  }
}

@keyframes circle {
  0% {
    height: 0;
    width: 0;
    opacity: 0;
  }
  100% {
    height: 300px;
    width: 300px;
    opacity: 0.6;
  }
}

.section {
  height: max-content; /* Full viewport height */
  position: relative; /* Position relative for sticky child */
  background-color: #141414;
  color: #fff;
  width: 100vw;
  .svg-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.1;
  }

  .mobile-screen-lines {
    width: 100%;
    height: 100%;
  }

  .line {
    stroke: #757575;
    stroke-width: 2;
    opacity: 0.5;
  }

  .content-wrapper {
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }
}

.footer {
  height: max-content;
  width: 100%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .footer-wrapper {
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 150px;
  }

  .footer-info-wrapper {
    margin-top: auto;
    width: 100%;
    height: 200px;
  }
}

.form-wrapper-style {
  height: max-content;
  width: clamp(250px, 80%, 400px);
  border-radius: 16px;
  background: #141414;
  box-shadow: inset 13px 13px 26px #090909, inset -13px -13px 26px #1f1f1f;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
  margin-top: auto;
  z-index: 21;

  .footer-botton-style {
    border: 4px solid #adadad;
    background-color: transparent;
    color: #adadad;
    padding: 8px 20px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 800;
  }

  .form-header {
    margin-top: 12px;
    font-weight: 700;
    font-size: larger;
  }

  .form-style {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    padding: 24px;
  }

  .form-item {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;

    input {
      width: 100%;
      border: none;
      outline: none;
      background-color: transparent;
      height: 40px;
      border-bottom: 2px solid #777777;
      color: #ffffff;
      font-size: 16px;
      transition: 0.4s all;
    }

    label {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      opacity: 1;
      color: #838383;
      pointer-events: none;
    }

    input:focus + label {
      top: -6px;
      opacity: 0.7;
      font-size: 12px;
    }
    input.error {
      border-bottom-color: red;
    }
    .error-text {
      color: red;
      font-size: 12px;
      margin-top: 4px;
      position: absolute;
      bottom: -20px;
    }
  }
}

.red-form-style {
  position: fixed;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;

  .form-wrapper-style {
    margin-top: 0;
  }
}

.close-button {
  position: absolute; /* Make it absolute */
  top: 10px; /* Adjust the distance from the top */
  right: 10px; /* Adjust the distance from the right */
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  cursor: pointer;
  transform: rotate(135deg) scale(0.5);
}

.line1,
.line2 {
  position: absolute;
  background-color: white; /* Change line color to white */
  width: 100%;
  height: 6px; /* Increase line thickness for bold effect */
  transition: transform 0.3s ease;
  top: 50%;
  left: 0;
  transform-origin: center;
}

.line1 {
  transform: translateY(-50%);
}

.line2 {
  transform: translateY(-50%) rotate(90deg);
}

.footer {
  background-color: #1f2937;
  color: #222222;

  .footer-container {
    max-width: 80rem;
    margin: 0 auto;
    padding: 3rem 1rem;
  }

  .footer-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .footer-brandSection {
    .footer-brandLink {
      display: flex;
      align-items: center;
    }

    .footer-logo {
      width: 2.5rem;
      height: 2.5rem;
      background-color: #fbbf24;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .footer-logoText {
        color: #1f2937;
        font-weight: bold;
        font-size: 1.25rem;
      }
    }

    .footer-brandName {
      color: #222222;
      font-weight: bold;
      font-size: 1.25rem;
    }

    .footer-description {
      margin-top: 1rem;
      font-size: 0.875rem;
      max-width: 16rem;
    }
  }

  .footer-sectionTitle {
    color: #222222;
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  .footer-linkList,
  .footer-contactList {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .footer-link,
  .footer-socialLink {
    text-decoration: none;
    color: #222222;
    display: block;
    padding: 0.5rem 0;
    transition: color 0.3s;

    &:hover {
      color: #fbbf24;
    }
  }

  .footer-contactItem {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
  }

  .footer-socialIcons {
    display: flex;
    gap: 1rem;
  }

  .footer-bottom {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid #374151;
    text-align: center;
    font-size: 0.875rem;
  }
}

.projects-wrapper {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #141414;

  .cards-container {
    position: relative;
    height: max-content;

    .cards-wrapper-style-projects {
      position: sticky;
      top: 0px;
      height: 100svh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
