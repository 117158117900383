.imageContainer {
  position: relative;
  width: clamp(120px, 28vw, 200px);
  aspect-ratio: 263 / 377;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  > * {
    position: absolute;
    inset: 0;
    width: 100% !important;
    height: 100% !important;
    object-fit: fill;
  }
}

canvas {
  display: block;
}
