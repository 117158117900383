// HexagonLoader.scss
$bg-light: #e3e4e8;
$fg-light: #17181c;
$bg-dark: #17181c;
$fg-dark: #e3e4e8;

.loader-container {
  font: bold 1em/1.5 "Comfortaa", sans-serif;
  display: grid;
  place-items: center;
  align-content: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  height: 100vh;
  width: 100vw;

  p {
    margin-top: 1.5em;
  }
}

.hexagon {
  --bg: #{$bg-light};
  --fg: #{$fg-light};
  --dur: 8s;

  margin-bottom: 1.5em;
  overflow: hidden;
  position: relative;
  width: 15em;
  height: 15em;
  color: var(--fg);

  &__group,
  &__sector,
  &__sector:before,
  &__sector:after {
    position: absolute;
  }

  &__group {
    width: 100%;
    height: 100%;

    @for $i from 2 through 6 {
      &:nth-child(#{$i}) .hexagon__sector,
      &:nth-child(#{$i}) .hexagon__sector:before,
      &:nth-child(#{$i}) .hexagon__sector:after {
        animation-delay: calc(var(--dur) * #{-($i - 1)}/ 6);
      }
    }

    &:nth-child(odd) {
      transform: rotate(30deg);
    }
  }

  &__sector {
    animation-name: moveOut1;
    top: calc(50% - 0.1em);
    left: calc(50% - 0.1em);
    width: 0.2em;
    height: 0.2em;

    &,
    &:before,
    &:after {
      animation-duration: var(--dur);
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      width: 0.2em;
      height: 0.2em;
    }

    @for $i from 2 through 6 {
      &:nth-child(#{$i}) {
        animation-name: moveOut#{$i};
      }
    }

    &:before,
    &:after {
      animation-name: ripple;
      background-color: currentColor;
      border-radius: 0.1em;
      content: "";
      display: block;
      top: 0;
      left: 0;
      transform-origin: 0.1em 0.1em;
    }

    &:before {
      transform: rotate(-30deg);
    }

    &:after {
      transform: rotate(-150deg);
    }
  }
}

@media (prefers-color-scheme: dark) {
  .hexagon {
    --bg: #{$bg-dark};
    --fg: #{$fg-dark};
  }
}

// Animations
@keyframes moveOut1 {
  from {
    transform: translateY(0) scale(0);
  }
  3% {
    transform: translateY(0.2em) scale(1);
  }
  97% {
    transform: translateY(7.3em) scale(1);
  }
  to {
    transform: translateY(7.5em) scale(0);
  }
}

@for $i from 2 through 6 {
  @keyframes moveOut#{$i} {
    $angle: ($i - 1) * 60deg;
    from {
      transform: rotate(#{$angle}) translateY(0) scale(0);
    }
    3% {
      transform: rotate(#{$angle}) translateY(0.2em) scale(1);
    }
    97% {
      transform: rotate(#{$angle}) translateY(7.3em) scale(1);
    }
    to {
      transform: rotate(#{$angle}) translateY(7.5em) scale(0);
    }
  }
}

@keyframes ripple {
  from,
  to {
    width: 0.2em;
  }
  33% {
    width: 2.4em;
  }
}
