// ProjectsGrid.module.scss
$ruler: 16px;
$color-red: #ae1100;
$color-bg: #ebecf0;
$color-shadow: #babecc;
$color-white: #fff;

.projects-wrapper {
  h1 {
    text-align: center;
    padding-top: 48px;
  }
}

.backButton {
  position: fixed;
  top: $ruler * 1.5;
  right: $ruler * 1.5;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: $ruler * 0.8;
  border: 0;
  outline: 0;
  font-size: $ruler * 0.9;
  border-radius: $ruler * 20;
  color: $color-red;
  font-weight: bold;
  text-shadow: 1px 1px 0 $color-white;
  box-shadow: -5px -5px 20px $color-white, 5px 5px 20px $color-shadow;
  transition: all 0.2s ease-in-out;
  z-index: 3;
  cursor: pointer;

  &:hover {
    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
  }

  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow,
      inset -1px -1px 2px $color-white;
  }
}

.projectsContainer {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.projectItem {
  padding: 24px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    border-bottom: none;
  }
}

.projectHeader {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.projectTitle {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  color: #000;
}

.projectStatus {
  font-size: 14px;
  color: #666;
  padding: 4px 12px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);
}

.imageScroller {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.imageContainer {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px; // Space for scrollbar

  // Hide scrollbar
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE/Edge

  &::-webkit-scrollbar {
    display: none; // Chrome/Safari
  }
}

.imageWrapper {
  flex: 0 0 auto;
  width: 80%;
  aspect-ratio: 4/3;
  overflow: hidden;
  border-radius: 4px;

  &:hover {
    .projectImage {
      transform: scale(1.05);
    }
  }
}

.projectImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.skeleton {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #e0e0e0 25%, #f3f3f3 50%, #e0e0e0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
  border-radius: 8px;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
