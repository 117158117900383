$ruler: 16px;
$color-red: #ae1100;
$color-bg: #ebecf0;
$color-shadow: #babecc;
$color-white: #fff;

.wrapper {
  padding: $ruler $ruler * 3 $ruler * 5;
  margin: 0 auto;
  background-color: $color-bg;
  border-bottom-left-radius: 64px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  margin-bottom: 12px;
  min-height: 670px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  div,
  p {
    color: $color-shadow;
    text-shadow: 1px 1px 1px $color-white;
  }
}

.segment {
  padding: $ruler * 2 0;
  text-align: center;

  h1 {
    color: $color-red;
  }
}

.error-text {
  color: $color-red;
  font-size: 12px;
  margin-top: 4px;
  margin-left: 4px;
}

.button,
.input {
  border: 0;
  outline: 0;
  font-size: $ruler;
  border-radius: $ruler * 20;
  padding: $ruler;
  background-color: $color-bg;
  text-shadow: 1px 1px 0 $color-white;
  position: relative;
}

.label {
  display: block;
  margin-bottom: $ruler * 1.5;
  width: 100%;
}

.input {
  margin-right: $ruler/2;
  box-shadow: inset 2px 2px 5px $color-shadow, inset -5px -5px 10px $color-white;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;

  &:focus {
    box-shadow: inset 1px 1px 2px $color-shadow,
      inset -1px -1px 2px $color-white;
  }
}

.button {
  color: $color-red;
  font-weight: bold;
  box-shadow: -5px -5px 20px $color-white, 5px 5px 20px $color-shadow;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  text-align: center;

  &:hover {
    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
  }

  &:active {
    box-shadow: inset 1px 1px 2px $color-shadow,
      inset -1px -1px 2px $color-white;
  }
}
