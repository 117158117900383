// _faq.scss
.faq-container {
  min-height: 100svh;
  width: 100%;

  .faq-wrapper {
    width: 100%;
    max-width: 48rem; // 3xl in Tailwind
    margin: 0 auto;
    padding: 2rem;
  }

  .header {
    text-align: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 2.5rem;
      font-weight: 700;
      color: #cccccc;
      margin-bottom: 0.5rem;
    }

    p {
      color: #d1d5db;
    }
  }

  .search-bar {
    margin-bottom: 2rem;
    width: 100%;

    input {
      width: 100%;
      padding: 1rem 1.5rem;
      // border-radius: 1rem;
      color: #dfdfdf;
      background-color: #3b3b3b;
      transition: all 0.2s ease;
      border: none;

      &::placeholder {
        color: #b8babe;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .faq-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .faq-item {
    color: rgba(255, 255, 255, 0.95);
    overflow: hidden;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px -1px rgba(75, 75, 75, 0.1);

    &:hover {
      transform: translateY(-2px);
    }

    .question-button {
      width: 100%;
      padding: 1.25rem 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      background: none;
      border: none;
      cursor: pointer;

      .question-text {
        font-size: 1.125rem;
        font-weight: 500;
        color: #fcfcfc;
      }

      .icon {
        width: 1.25rem;
        height: 1.25rem;
        transition: transform 0.2s ease;

        &.active {
          transform: rotate(45deg);
        }
      }
    }

    .answer {
      padding: 0 1.5rem 1.25rem;
      color: #cccccc;
      line-height: 1.625;
      opacity: 0;
      max-height: 0;
      overflow: hidden;
      transition: all 0.5s ease;

      &.active {
        opacity: 1;
        max-height: 1000px; // Arbitrary large value
      }
    }
  }

  .no-results {
    text-align: center;
    padding: 2rem 0;
    color: #d1d5db;
  }
}

// Animations
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.slide-enter {
  animation: slideDown 0.2s ease forwards;
}

.slide-exit {
  animation: slideUp 0.2s ease forwards;
}
